import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/Footer";

class NotFound extends Component {
  componentDidMount(){
    document.title = "Error"
  }
  render() {
    return (
      <>
        <div className="not_found">
          <div className="cont">
            <img src={`${process.env.PUBLIC_URL}/images/404.jpg`} alt="" />
            <h5>page not Found</h5>
            <Link to="/">Back to Home</Link>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default NotFound;
