import React, { Component } from "react";
import { Company_Url } from "./Apis";
import OrganizationCard from "./OrganizationCard";
import axios from "axios";
import Loading from "./Loading";

class OurCompany extends Component {

  state = {
    data: [],
    loading: true
  }

  componentDidMount=()=>{
    this.getData();
  }

  getData=()=>{
    axios.get(`${Company_Url}/`).then((res)=>{
      if(res.status == 200){
        this.setState({
          loading: false,
          data: res.data
        })
      }
    })
  }

  render() {
    const {data, loading} = this.state;
    return (
      <>
        <div className="our_company" id="organizations">
          <div className="container">
            <div className="section_header">
              <h6>our</h6>
              <h5>Organization</h5>
            </div>
            <div className="row align-items-center">
              {data.map((item)=>(
                 <div className="col-6 col-md-6 col-lg-4" key={item.id}>
                 <OrganizationCard title={item.name} link={item.companyLink}  image={item.imagePath} pargraph={item.description}/>
               </div>
              ))}
            </div>
          </div>
        </div>
        <div className={loading ? null : "removeLoading"}>
          <Loading />
        </div>
      </>
    );
  }
}

export default OurCompany;
