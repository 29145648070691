import React, { Component } from "react";

import "./assets/css/style.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CheckCirtificate from "./pages/CheckCirtificate";
import CertificateDetails from "./pages/CertificateDetails";
// react-toastify
import { ToastContainer } from "react-toastify";
import "../node_modules/react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import TopNavbar from "./component/TopNavbar";
import NavBar from "./component/NavBar";
import NotFound from "./pages/NotFound";
import AboutUsPage from "./pages/AboutUsPage";
import SideNav from "./component/SideNav";
import Organizations from "./pages/Organizations";
import OurServices from "./pages/OurServices";
import ContactUsPage from "./pages/ContactUsPage";

class App extends Component {
  render() {
    const openNav = () => {
      
      document.querySelector(".sideNav").style.left = "0%";
      document.querySelector(".openSideMenue").style.left = "0%";
    };

    const closeNav = () => {
      document.querySelector(".sideNav").style.left = "-100%";
      document.querySelector(".openSideMenue").style.left = "-100%";

    };

    const closeoverlayNav = () => {
      document.querySelector(".sideNav").style.left = "-100%";
      document.querySelector(".openSideMenue").style.left = "-100%";
    };

    return (
      <>
        <ToastContainer autoClose={5000} icon={true} />
        <Router>
          <div className="App">
            <div className="openSideMenue" onClick={closeoverlayNav}></div>
            <TopNavbar />
            <NavBar openNav={openNav} />
            <SideNav closeNav={closeNav} />
            <Routes>
              <Route
                exact
                path="/check-certificate"
                element={<CheckCirtificate />}
              />
              <Route
                exact
                path="/cirtificate-detials"
                element={<CertificateDetails />}
              />
              <Route exact path="/about-us" element={<AboutUsPage />} />
              <Route exact path="/services" element={<OurServices />} />
              <Route exact path="/contactus" element={<ContactUsPage />} />
              <Route exact path="/organizations" element={<Organizations />} />
              <Route exact path="/" element={<Home />} />
              <Route exact path="*" element={<NotFound />} />
            </Routes>
          </div>
        </Router>
      </>
    );
  }
}

export default App;
