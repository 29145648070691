import React, { Component } from 'react'

class TopNavbar extends Component {
  render() {
    return (
      <>
        <div className="top_navbar">
          <div className="container">
            <div className="wrapper">
              <div className="logo">
                <a href=""><img src={`${process.env.PUBLIC_URL}/images/logo2.png`} alt="logo" /></a>
              </div>
              <div className="social">
                <ul className='list-unstyled'>
                  {/* <li><a href=""><i className='fab fa-facebook-f'></i></a></li>
                  <li><a href=""><i className='fab fa-twitter'></i></a></li>
                  <li><a href=""><i className='fab fa-youtube'></i></a></li> */}
                  <li><a href="/#contactus"><i className='fas fa-envelope'></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default TopNavbar;