import React, { Component } from "react";

class Breadcrumb extends Component {
  render() {
    const {image, title} = this.props;
    return (
      <>
        <div
          className="breadcrumb"
          style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/images/${image}')`,
          }}
        >
          <div className="container">
            <h5> {title} </h5>
          </div>
        </div>
      </>
    );
  }
}

export default Breadcrumb;
