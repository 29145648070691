import React, { Component } from 'react'

export default class Loading extends Component {
  render() {
    return (
      <>
        <div className="loading_parent">
          <div className="loading"></div>
        </div>
      </>
    )
  }
}
