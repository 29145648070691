import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

class NavBar extends Component {
  render() {
    const {openNav} = this.props;
    return (
      <>
        <nav className="custom_navbar">
          <div className="container">
            <div className="wrapper">
              <div className="side_icon" onClick={openNav}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/menu-svgrepo-com.svg`}
                  alt=""
                />
              </div>
              <ul className="list-unstyled list">
                <li>
                  <Link className="nav_link" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="nav_link" to="/about-us">
                    About
                  </Link>
                </li>
                <li>
                  <Link className="nav_link" to="/services">
                    Services
                  </Link>
                </li>
                <li>
                  <Link className="nav_link" to="/organizations">
                    Organizations
                  </Link>
                </li>
                <li>
                  <Link className="nav_link" to="/check-certificate">
                    Certificates
                  </Link>
                </li>
                {/* <li> <Link className="nav_link" to="/credits"> Credits </Link> </li> */}
                <li>
                  <Link className="nav_link" to="/contactus">
                    Contact Us
                  </Link>
                </li>
              </ul>
              {/* <div className="join_us">
                <Link to="#" className='join_us_link'>Join Us</Link>
              </div> */}
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default NavBar;
