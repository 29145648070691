import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <>
        <footer className="sitefooter">
          <div className="container">
            <div className="wrapper">
              <div className="copy">
                <h6>All copy reserved by american borad &copy; 2022</h6>
              </div>
              <div className="contact_info">
                <ul className="list-unstyled">
                  <li>
                    <a href="">
                      <i className="fas fa-envelope"></i>
                      <span>customerservice@americanboard-us.com</span>
                    </a>
                  </li>
                  {/* <li>
                    <a href="">
                      <i className="fas fa-phone-alt"></i>
                      <span>01098586783</span>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
