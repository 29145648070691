import axios from "axios";
import React, { Component } from "react";
import { getDataBySerialNumber } from "../component/Apis";
import {
  useParams,
  useNavigate,
  Router,
  useLocation,
  useNavigationType,
  Navigate
} from "react-router-dom";
import Loading from "../component/Loading";
// react-toastify
import { toast, ToastContainer } from "react-toastify";
// import "../node_modules/react-toastify/dist/ReactToastify.css";
import CheckCirtificate from "./CheckCirtificate";
import Breadcrumb from "../component/Breadcrumb";
import Footer from "../component/Footer";

const withRouter = (WrappedComponent) => (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const navigationType = useNavigationType();
  // etc... other react-router-dom v6 hooks
  return (
    <WrappedComponent
      {...props}
      navigate={navigate}
      params={params}
      location={location}
      navigationType={navigationType}
      // etc...
    />
  );
};

class CertificateDetails extends Component {
  state = {
    data: {},
    loading: true,
  };

  componentDidMount() {
    // console.log(this.props.location.state);
    const locationstate = this.props.location.state;
    if (locationstate === null) {
      this.props.navigate("/check-certificate")
      window.location.reload();
      // return (
      // )
    } else {
      const data = this.props.location.state.data;
      // console.log(data);
      this.setState({
        data,
        loading: false,
      });
      document.title = "Certificate";
    }
    // this.retreveData(serialNumber);
  }


  // logOut = (e) => {
  //   e.preventDefault();
  //   sessionStorage.removeItem("token");
  //   this.props.navigate("/");
  //   window.location.reload();
  // };

  // getToken() {
  //   const token = sessionStorage.getItem("token");
  //   return token;
  // }

  render() {
    const {
      sNumber,
      traningName,
      name,
      date,
      gpa,
      durationByHour,
      location,
      centerName,
    } = this.state.data;
    const { loading } = this.state;

    // const token = this.getToken();
    // if (!token) {
    //   // console.log("yes")
    //   return (
    //     <>
    //       <CheckCirtificate />
    //     </>
    //   );
    // }

    return (
      <>
        <Breadcrumb title="Certificate Details" image="cerdetials.jpg" />
        <div className="certificate_detials">
          <img
            src={`${process.env.PUBLIC_URL}/images/big-cir.png`}
            alt=""
            className="circle"
          />
          <div className="container">
            <div className="certificate_card">
              <div className="card_body">
                <ul className='list-unstyled'>
                  <li><span className='title'>Serial Number</span> <span className='value'>{sNumber}</span> </li>
                  <li><span className='title'>Center Name</span> <span className='value'>{centerName}</span> </li>
                  <li><span className='title'>training name</span> <span className='value'>{traningName}</span> </li>
                  <li><span className='title'>Name</span> <span className='value'>{name}</span> </li>
                  <li><span className='title'>Date</span> <span className='value'>{date?.split('T')[0]}</span> </li>
                  <li><span className='title'>Grade</span> <span className='value'>{gpa}</span> </li>
                  <li><span className='title'>duration</span> <span className='value'>{durationByHour} Hour</span> </li>
                  <li><span className='title'>location</span> <span className='value'>{location}</span> </li>
                </ul>
              </div>
            </div>
            {/* <div className="btn_close">
              <button onClick={this.logOut} className="btn btn-dark">
                Close
              </button>
            </div> */}
          </div>
        </div>
        {loading ? <Loading /> : null}
        <Footer />
      </>
    );
  }
}

export default withRouter(CertificateDetails);
