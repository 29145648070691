import React, { Component } from "react";
import Breadcrumb from "../component/Breadcrumb";
import Footer from "../component/Footer";
import Loading from "../component/Loading";

class AboutUsPage extends Component {

  state = {
    loading: true
  }
  
  componentDidMount(){
    document.title = "About Us";
    // this.handleLoad()
  }

  // handleLoad = () => {
  //   // console.log("yes");
  //   this.setState({
  //     loading: false,
  //   });
  // };

  render() {
    // const {loading} = this.state;
    return (
      <>
        <Breadcrumb title="About Us" image="about.png" />
        <div className="about_us">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="about_img">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logo2.png`}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="about_img about_img_parnent">
                  <div className="img_wrapper">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/flag2.jpg`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="about_content">
              <p>
                American Board Of Professional Studies that aims to create
                leaders in all parts of the world and is considered one of the
                most reliable global professional boards approved by the United
                States of America. An official registration was made when
                registering company numbers in Wyoming, USA, under the
                registration number 2022-001140413
              </p>
              <h5> specializes in the following areas:</h5>
              <ul className="list-unstyled">
                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-3">
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/human.png`}
                        alt=""
                      />
                      <span>Human Development</span>
                    </li>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-3">
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/buttons.png`}
                        alt=""
                      />
                      <span>media</span>
                    </li>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-3">
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/mortarboard.png`}
                        alt=""
                      />
                      <span>Academic studies</span>
                    </li>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-3">
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/language.png`}
                        alt=""
                      />
                      <span>Languages</span>
                    </li>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-3">
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/leadership.png`}
                        alt=""
                      />
                      <span>Leadership</span>
                    </li>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-3">
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/management.png`}
                        alt=""
                      />
                      <span>Management</span>
                    </li>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-3">
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/analysis.png`}
                        alt=""
                      />
                      <span>Train the trainers</span>
                    </li>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-3">
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/tools.png`}
                        alt=""
                      />
                      <span>Crafts</span>
                    </li>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-3">
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/budget.png`}
                        alt=""
                      />
                      <span>Accounting</span>
                    </li>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-3">
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/it.png`}
                        alt=""
                      />
                      <span>Information technology</span>
                    </li>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-3">
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/computer-science.png`}
                        alt=""
                      />
                      <span>Computer Science</span>
                    </li>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-3">
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/planning.png`}
                        alt=""
                      />
                      <span>Strategic, operational and personal planning.</span>
                    </li>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-3">
                    <li>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/guarantee-certificate.png`}
                        alt=""
                      />
                      <span>Where it grants a passing certificate and international
                      professional</span>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <Footer />

        {/* <div className={loading ? null : "removeLoading"}>
          <Loading />
        </div> */}
      </>
    );
  }
}

export default AboutUsPage;
