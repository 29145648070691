import React, { Component } from "react";
import Breadcrumb from "../component/Breadcrumb";
import Footer from "../component/Footer";
import OurCompany from "../component/OurCompany";

class Organizations extends Component {
  componentDidMount(){
    document.title = "Organizations";
  }
  render() {
    return (
      <>
        <Breadcrumb title="OUR ORGANIZATIONS" image="companybr.jpg" />

        <div className="inner_page_compnent">
          <OurCompany />
        </div>

        <Footer />
      </>
    );
  }
}

export default Organizations;
